import React from "react";
import { Box, Stack } from "@mui/material";
import { AttachmentIcon, AttachmentItem, CheckIcon } from "./PrevAnswers";

import { Col, Row } from "reactstrap";
import { RenderAnswerView } from "./PrevAnswers/RenderAnswerView";
import { StackContainer } from "./StackContainer";
import { Text } from "../../../../shared-components/my-scope/atoms/text";
const boldFont = { fontWeight: "bold", fontSize: 16, color: "#0E2A37" };
const regFont = { fontSize: boldFont.fontSize, color: "#505050" };
const thinFont = { fontSize: boldFont.fontSize, color: "#0E2A37" };

const rowStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const effraFont = { fontFamily: "Effra" };

export const PrevAnswerInDetailedReport = (props) => {
  return (
    <Stack spacing={6} style={effraFont}>
      <StackContainer spacing={2}>
        {props?.reason && (
          <Box>
            <Text
              textType="bold"
              fontSize="font-18"
              mobileFontSize="font-16-mobile"
              Tag="div"
              style={{ ...boldFont, color: "#148287" }}
            >
              {props?.caliber.is_answered === "answered" &&
                "تم إرجاع هذا البند، و تحديث بياناته من قبل المنشأة"}
              {props?.caliber.is_answered === "returned" &&
                "تم إرجاع هذا البند لتحديث بياناته من قبل المنشأة - لم يتم الاستجابة لطلب تحديث بيانات البند"}
            </Text>
            <Text
              Tag={"span"}
              textType="bold"
              fontSize="font-18"
              mobileFontSize="font-16-mobile"
              style={thinFont}
            >
              {"سبب الإرجاع : "}
            </Text>
            <Text
              Tag={"span"}
              textType="roman"
              fontSize="font-18"
              mobileFontSize="font-16-mobile"
              style={regFont}
            >
              {props?.reason}
            </Text>
          </Box>
        )}
        {props?.details && (
          <Box style={{ marginTop: 4 }}>
            <Text
              Tag={"span"}
              textType="bold"
              fontSize="font-18"
              mobileFontSize="font-16-mobile"
              style={thinFont}
            >
              {"التفاصيل : "}
            </Text>
            <Text
              Tag={"span"}
              textType="roman"
              fontSize="font-18"
              mobileFontSize="font-16-mobile"
              style={regFont}
            >
              {props?.details}
            </Text>
          </Box>
        )}
        {props?.caliber.is_answered === "not_answered" ? null : (
          <Row>
            <Col>
              <div
                className="horizontal-line"
                style={{
                  marginTop: 7,
                  marginBottom: 7,
                  marginRight: 1,
                  marginLeft: 0,
                  borderTop: "1px solid #74727233",
                }}
              ></div>
            </Col>
          </Row>
        )}
        {props?.caliber.is_answered === "not_answered" ? null : (
          <div style={{ marginTop: 49 }}>
            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              <CheckIcon />
              <Text
                Tag={"div"}
                textType="bold"
                fontSize="font-18"
                mobileFontSize="font-16-mobile"
                style={{ ...boldFont, color: "#148287" }}
              >
                {"تقييم المنشأة - قبل التعديل "}
              </Text>
            </Stack>

            <div style={{ ...rowStyles, columnGap: 48, marginTop: 4 }}>
              <Box sx={{ ...rowStyles, gap: 6 }}>
                <Text
                  Tag={"span"}
                  textType="roman"
                  fontSize="font-18"
                  mobileFontSize="font-16-mobile"
                  style={regFont}
                >
                  {
                    "بعد الإطلاع على شرح البند الموجود أعلاه، هل توافق على هذا البند؟"
                  }
                </Text>
                {props?.caliber.is_answered === "not_answered" ? null : (
                  <RenderAnswerView type={props?.answer} />
                )}
              </Box>
            </div>
          </div>
        )}
        {props?.attachments?.length &&
        props?.caliber.is_answered === "answered" ? (
          <div style={{ marginTop: 49 }}>
            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              <AttachmentIcon />
              <Text
                Tag={"div"}
                textType="bold"
                fontSize="font-18"
                mobileFontSize="font-16-mobile"
                style={{ ...boldFont, color: "#148287" }}
              >
                {"المرفقات"}
              </Text>
            </Stack>

            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              {props?.attachments?.map((attachItem, idx) => (
                <AttachmentItem key={attachItem?.id + idx} item={attachItem} />
              ))}
            </Stack>
          </div>
        ) : null}
      </StackContainer>
    </Stack>
  );
};
