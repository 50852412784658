import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Content from "../../containers/Content/Content";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as auth from "../../actions/authActions";
import {
  aboutFastTrack,
  aboutProgram,
  programBenefits,
  programStatus,
} from "./static-data";
import "./homepage.css";
import { Link } from "react-scroll";
import DescriptionWithBackground from "../reusableComponents/DescriptionWithBackground";
import ButtonWithIcon from "../reusableComponents/ButtonWithIcon";
import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import SectionDescriptionWithIcon from "../reusableComponents/SectionDescriptionWithIcon";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import Button from "../Button/Button";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";
import AnimationLeftToRight from "../AnimationLeftToRight/AnimationLeftToRight";
import AnimationBottomToTop from "../AnimationBottomToTop/AnimationBottomToTop";
import PopUp from "../../views/Components/PopUp/PopUp";
import { fetchImsInfo } from "../../actions/imsInfo";

const { object } = PropTypes;

const HomePage = (props) => {
  const myRef = useRef();
  const fistElement = useRef();
  const [programStats, setProgramStats] = useState({});

  const syncStatistics = (imsInfoData) => {
    programStatus.description[0].number = imsInfoData.establishments_count;
    programStatus.description[1].number = imsInfoData.assessments_count;
    return programStatus;
  };

  useEffect(() => {
    fetchImsInfo().then((result) => {
      const programStatus = syncStatistics(result);
      setProgramStats(programStatus);
    });

    let elem = document.getElementById("website-container-margins");
    elem.style.margin = 0;
    window.scrollTo({ top: 0, behavior: "smooth" });
    fistElement.current.focus();
    return () => {};
  }, []);

  const handleUserKeyPress = (e) => {
    const active = document.activeElement;
    if (e.keyCode === 40 && active.nextSibling) {
      active.nextSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.nextSibling.focus();
    }
    if (e.keyCode === 38 && active.previousSibling) {
      active.previousSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.previousSibling.focus();
    }
  };

  useEffect(() => {
    const node = myRef.current;
    node.addEventListener("keydown", handleUserKeyPress);

    return () => {
      node.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  const logIn = () => {
    window.location =
      process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
    window.location.reload();
  };

  //const logInToOSH = () => {
  //  window.location = window.env.SECOND_URI + "/#/redirect";
  // };

  const goToInteractiveGuide = () => {
    props.history.push("/interactiveGuide");
  };
  const goToAboutProgram = () => {
    props.history.push("/about");
  };
  const gotToFaq = () => {
    props.history.push("/faq");
  };
  const goToFastTrack = () => {
    props.history.push("/specialTrack");
  };

  const StatsWrapper = ({ wrapper, children }) => wrapper(children);

  const renderStatsComponent = (statsArray) => {
    return statsArray?.map((item, i) => {
      return (
        <StatsWrapper
          key={`${i}`}
          wrapper={(children) =>
            i === 0 ? (
              <AnimationRightToLeft>{children}</AnimationRightToLeft>
            ) : (
              <AnimationLeftToRight>{children}</AnimationLeftToRight>
            )
          }
        >
          <div
            className={`stats-component ${
              i === 0 ? "stats-component-first-child" : ""
            }`}
          >
            <img src={item.icon} className="stats-icon-size flex-1" />
            <div className="stats-data">
              {/* <span className="stats-number">{item.number}</span> */}
              <Text
                Tag="span"
                className="stats-number"
                fontSize={"font-40"}
                textType="bold"
                mobileFontSize="font-21-mobile"
              >
                {item.number}
              </Text>

              <Text
                Tag="span"
                fontSize={"font-26"}
                textType="roman"
                mobileFontSize="font-16-mobile"
                className="stats-description"
              >
                {item.description}
              </Text>
            </div>
          </div>
        </StatsWrapper>
      );
    });
  };

  return (
    <Content history={props.history}>
      <div className="guide-page-wrap">
        <div className="animated fadeIn main-page" ref={myRef}>
          <div
            ref={fistElement}
            tabIndex="0"
            className="row jumbotron banner-1 "
            style={{
              marginBottom: 0,
              background: " url('/img/hero-bg.jpg') 50% 100% no-repeat",
              backgroundSize: "cover",
              display: "flex",
              minHeight: "704px",
            }}
          >
            <div className="banner-content">
              <Text
                Tag="div"
                fontSize={"font-48"}
                textType="roman"
                mobileFontSize="font-28-mobile"
                className="main-title-home"
              >
                منصة
              </Text>
              <Text
                Tag="div"
                fontSize={"font-72"}
                textType="roman"
                mobileFontSize="font-40-mobile"
                className="subtitle"
              >
                التقييم الذاتي
              </Text>

              <Text
                Tag="div"
                fontSize={"font-40"}
                textType="roman"
                mobileFontSize="font-22-mobile"
                className="main-description-home"
              >
                تأكد من امتثال منشأتك
              </Text>
              <div className="main-buttons">
                <Button
                  onClick={() => {
                    logIn();
                  }}
                  text="ابدأ التقييم"
                  bigButton
                  isBold
                  fontSize={"font-40"}
                  mobileFontSize={"font-16-mobile"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Link
                className="test6"
                to="main-thread"
                spy={true}
                smooth={true}
                duration={500}
                offset={-40}
              >
                <img src={"img/down-arrow.svg"} />
              </Link>
            </div>
          </div>
          <div
            className="main-thread sections-padding section-bottom-margin"
            id="main-thread"
            tabIndex="1"
          >
            <section className="flex-1 section-inner-style small-screen-view ">
              <div className="flex-1">
                <div style={{ maxWidth: "570px", flexDirection: "column" }}>
                  <AnimationRightToLeft>
                    <TitleWithSubTitle
                      title={aboutProgram.title}
                      subtitle={aboutProgram.subtitle}
                    />
                  </AnimationRightToLeft>
                  <AnimationBottomToTop>
                    <Text
                      Tag="div"
                      textType="roman"
                      fontSize={"font-26"}
                      mobileFontSize={"font-18-mobile"}
                      className="thread-text"
                    >
                      {aboutProgram.description}
                    </Text>
                    <span className="home-page-about-program-link">
                      <ButtonWithIcon
                        text="اقرأ المزيد عن البرنامج"
                        goTo={goToAboutProgram}
                        smallArrow
                        textType="bold"
                        fontSize={"font-24"}
                        mobileFontSize={"font-16-mobile"}
                      />
                    </span>
                  </AnimationBottomToTop>
                </div>
              </div>
              <div className="image-container">
                <AnimationLeftToRight>
                  <img
                    src={"img/aboutProgram.svg"}
                    className="image-size-about"
                  />
                </AnimationLeftToRight>
              </div>
            </section>
          </div>

          <section
            tabIndex="2"
            className="flex-1 section-outer-style sections-padding"
          >
            <SectionDescriptionWithIcon
              data={programBenefits}
              imageSrc="img/programBenefits.svg"
              descriptionMaxwidth="thread-text-max-width"
            />
          </section>

          <section
            className="flex-1 stats-section section-outer-style sections-padding"
            tabIndex="3"
          >
            <div className="flex-1 section-inner-style">
              <div
                style={{
                  flexDirection: "column",
                }}
                className="flex-1 "
              >
                <AnimationRightToLeft>
                  <TitleWithSubTitle
                    title={programStats?.title}
                    subtitle={programStats?.subtitle}
                    customStyle="stats-title"
                  />
                </AnimationRightToLeft>
                <div className="stats-wrapper">
                  {renderStatsComponent(programStats?.description)}
                </div>
              </div>
            </div>
          </section>

          <section
            className="flex-1 section-outer-style sections-padding"
            tabIndex="4"
            style={{ paddingBottom: "200px" }}
          >
            <DescriptionWithBackground
              data={aboutFastTrack}
              linkText="اقرأ المزيد عن الخدمة"
              onClick={goToFastTrack}
            />
          </section>
        </div>
      </div>
    </Content>
  );
};

HomePage.propTypes = {
  history: object.isRequired,
};

const mapStateToProps = (state) => ({
  ajaxCall: state.ajaxStatus,
  auth: state.auth,
  isLogin: state.auth.token !== null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(auth, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
