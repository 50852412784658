import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { paymentVerify } from "../../../actions/paymentActions";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./checkoutPage.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import {
  STATUSES,
  GRACE_PERIOD,
  DETAILED_REPORT_WITH_GRACE_PERIOD,
  DETAILEDREPORT,
  TRAINING,
  SADAD,
} from "../../../common/constants/paymentStatuses";
import RadioButton from "../../../views/Components/RadioButton/RadioButton";
import Button from "../../../components/Button/Button";

const CheckoutPage = (props) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedRadioButton, setSelectedRadioButton] = useState("creditCard");
  // prop.location.state.trackType
  useEffect(() => {
    const { checkout_id, service_type } = props.match.params;
    const { services, trainingData } = props;
    const { state } = props.location;
    if (selectedRadioButton === "creditCard") {
      const script = document.createElement("script", { id: "paymentScript" });
      const inlineScript = document.createElement("script", {
        id: "paymentInlineScript",
      });

      const paymentWrapper = document.getElementById("paymentWrapper");
      const service =
        service_type === TRAINING
          ? [
              {
                amount: trainingData.amount,
                vat:
                  trainingData.vat > 1
                    ? trainingData.vat / 100
                    : trainingData.vat,
                type: trainingData.name,
              },
            ]
          : services.filter((service) => {
              if (service_type === DETAILED_REPORT_WITH_GRACE_PERIOD)
                return (
                  service.type === GRACE_PERIOD ||
                  service.type === DETAILEDREPORT
                );
              else return service.type === service_type;
            });

      renderPaymentform(
        script,
        inlineScript,
        paymentWrapper,
        checkout_id,
        service_type,
        trainingData
      );

      setSelectedServices(service);
    }
  }, [selectedRadioButton]);

  const renderPaymentform = (
    script,
    inlineScript,
    paymentWrapper,
    checkoutId,
    service_type,
    trainingData = null
  ) => {
    script.src = `${process.env.REACT_APP_HYPER_PAY_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;
    inlineScript.innerHTML = `
      var wpwlOptions = {
        style: "plain",
        locale: "ar",
        showCVVHint: true,
        labels: {cardHolder: "اسم حامل البطاقة", cvv: "رمز الأمان",cardNumber:"رقم البطاقة",submit:"ادفع" },
        onReady: function(){
          $('.wpwl-label-cvv').html('رمز الأمان');
          $('.wpwl-button-pay').html('ادفع');
          $(".wpwl-group-cardNumber").after($(".wpwl-group-expiry").detach());
          $(".wpwl-group-cardHolder").after( $(".wpwl-group-cvv").detach());
          $('.wpwl-group-brand').hide();
      },
      'threeDIframeSize':{'width':'100%', 'height':'300px'},
    }`;

    paymentWrapper.append(inlineScript);
    paymentWrapper.appendChild(script);

    const form = document.createElement("form");
    form.action =
      service_type === TRAINING
        ? process.env.REACT_APP_ESTABLISHMENT_BASE_URI +
          `/#/checkoutResult/${service_type}/${trainingData.id}/${trainingData.status}`
        : process.env.REACT_APP_ESTABLISHMENT_BASE_URI +
          `/#/checkoutResult/${service_type}/0/0`;
    form.setAttribute("class", "paymentWidgets");
    form.setAttribute("data-brands", "MADA VISA MASTER AMEX");
    paymentWrapper.appendChild(form);
  };
  let totalAmount = 0;
  selectedServices.map((service) => {
    totalAmount += service.amount * service.vat + service.amount;
  });

  const { match, location } = props;
  const { sadad_number, bill_number, service_type } = match.params;
  return (
    <PageWrapper withBreadcrumbs breadcrumbLabel={"إتمام عملية الدفع"}>
      <div className="checkout-page">
        <Fragment>
          <div
            className={`checkout-page-card checkout-page-card__padding checkout-page-payment-card ${
              selectedServices?.length > 1
                ? ""
                : "checkout-page-card__one-service"
            }`}
          >
            <Text Tag="div" textType="h5" isBold>
              إتمام عملية الدفع
            </Text>
            {location.state && props.location.state.failMessage && (
              <div className="checkout-pag-payment-warning">
                <div className="checkout-pag-payment-warning__icon">
                  <img src={"img/alert_yellow_Icon.svg"} alt="alert icon" />
                </div>
                <Text Tag="span" textType="p5">
                  {location.state.failMessage}
                </Text>
              </div>
            )}
            <Text
              Tag="div"
              textType="p3"
              className="checkout-page-payment-type__title"
            >
              الدفع بواسطة :
            </Text>
            <div v className="checkout-page-payment-type-wrapper">
              <div
                className={`checkout-page-payment-type__card ${
                  selectedRadioButton === "creditCard"
                    ? "checkout-page-payment-type__card-color"
                    : ""
                }`}
              >
                <RadioButton
                  selectedRadioButton={selectedRadioButton}
                  value="creditCard"
                  label={
                    <span className="label-credit-card">فيزا أو بطاقة مدى</span>
                  }
                  onChange={() => {
                    setSelectedRadioButton("creditCard");
                  }}
                  isError={false}
                  icon={"img/hyperPayCards.svg"}
                />
              </div>

              <div
                className={`checkout-page-payment-type__card ${
                  selectedRadioButton === "sadad"
                    ? "checkout-page-payment-type__card-color"
                    : ""
                }`}
              >
                <RadioButton
                  selectedRadioButton={selectedRadioButton}
                  value="sadad"
                  label={
                    <span className="label-sadad">نظام سداد للمدفوعات</span>
                  }
                  onChange={() => {
                    setSelectedRadioButton("sadad");
                  }}
                  isError={false}
                  icon={"img/sadad.svg"}
                />
              </div>
            </div>
            <div className="checkout-page-payment-horizontal-line">
              <hr />
            </div>
            {selectedRadioButton === "sadad" ? (
              <Fragment>
                <Text Tag="div" textType="p4">
                  للدفع بواسطة نظام سداد يرجى استخدام المعلومات التالية :
                  <div className="checkout-page-payment-sadad-info">
                    رقم سداد:
                    <span className="checkout-page-payment-sadad-info__data">
                      {sadad_number}
                    </span>
                  </div>
                  <div className="checkout-page-payment-sadad-info">
                    اسم المفوتر:
                    <span className="checkout-page-payment-sadad-info__data">
                      ايداعات
                    </span>
                  </div>
                  <div className="checkout-page-payment-sadad-info">
                    رقم المفوتر:
                    <span className="checkout-page-payment-sadad-info__data">
                      903
                    </span>
                  </div>
                </Text>
                <Text
                  Tag="div"
                  textType="bold"
                  fontSize={"font-16"}
                  className="checkout-page-payment-sadad-info checkout-page-payment-sadad-info__message"
                >
                  يرجى العلم أنه في حال إتمام الدفع عن طريق سداد قد تتأخر عملية
                  تحديث حالة الدفع و إصدار الفاتورة.
                </Text>
                <Button
                  customStyle="wpwl-button-pay"
                  onClick={() => {
                    props.history.push({
                      pathname: `/checkoutResult/${SADAD}/0/0`,
                      state: { billNumber: bill_number },
                    });
                  }}
                  className=" btn-square"
                  text="تأكيد"
                  id="send-button"
                />
              </Fragment>
            ) : (
              <div id="paymentWrapper"></div>
            )}
          </div>
          <div
            className={`checkout-page-card  checkout-page-summary-card ${
              selectedServices?.length > 1
                ? ""
                : "checkout-page-card__one-service"
            }`}
          >
            <Text
              Tag="div"
              textType="h5"
              isBold
              className="checkout-page-card__summary-padding checkout-page-summary-card__title"
            >
              ملخص الطلب
            </Text>

            <hr className="responsive-hr" />
            <div
              className={`checkout-page-card__summary-padding checkout-page-summary-card__details ${
                selectedServices?.length > 1
                  ? "checkout-page-summary-card__details__two_services"
                  : ""
              }`}
            >
              {selectedServices.map((service, i) => {
                return (
                  <div
                    key={i}
                    className={`${
                      selectedServices?.length > 1 && i > 0
                        ? "checkout-page-summary-card__details__top-margin"
                        : ""
                    } `}
                  >
                    <div className="service-type-summary">
                      <Text Tag="div" textType="p5">
                        اسم الخدمة :
                      </Text>
                      <Text
                        Tag="div"
                        textType="p3"
                        isBold={window.screen.width > 1200}
                        className={"service-type-summary__name"}
                      >
                        {service_type === TRAINING
                          ? service.type
                          : STATUSES[service.type]}
                      </Text>
                    </div>
                    <Text
                      Tag="div"
                      textType="p4"
                      className={"checkout-page-summary-card__details__amounts"}
                    >
                      السعر
                      <Text
                        Tag="span"
                        textType="p4"
                        className={"show-Colon-on-mobile"}
                      >
                        :
                      </Text>
                      &nbsp;&nbsp;
                      {`${service.amount} ريال`}
                    </Text>
                    <Text Tag="div" textType="p4">
                      {`القيمة المضافة (${service.vat * 100}%)`}
                      <Text
                        Tag="span"
                        textType="p4"
                        className={"show-Colon-on-mobile"}
                      >
                        :
                      </Text>
                      &nbsp;&nbsp;
                      {`${service.amount * service.vat} ريال`}
                    </Text>
                  </div>
                );
              })}
            </div>

            <hr className="responsive-hr" />
            <div className="checkout-page-card__summary-padding checkout-page-summary-card__total">
              <Text Tag="div" textType="p3" isBold>
                المبلغ الإجمالي
                <Text
                  Tag="span"
                  textType="p3"
                  isBold
                  className={"show-Colon-on-mobile"}
                >
                  :
                </Text>
              </Text>
              <Text
                Tag="div"
                textType="p3"
                isBold
                className="checkout-page-summary-card__total__amount"
              >
                {`${totalAmount} ريال`}
              </Text>
            </div>
            <hr />
          </div>
        </Fragment>
      </div>
    </PageWrapper>
  );
};

CheckoutPage.propTypes = {
  paymentVerify: PropTypes.func,
  establishmentProfile: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  services: PropTypes.array,
  trainingData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishment.establishmentProfile,
  services: state.payment.paymentServices,
  trainingData: state.trainings.trainingData,
});
const mapDispatchToProps = (dispatch) => ({
  paymentVerify: (establishmentId, checkoutId) =>
    dispatch(paymentVerify(establishmentId, checkoutId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutPage));
