import ReactDOM from "react-dom";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import DropDownList from "./DropDownList";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { AccessiblityContainer } from "../AccessibilityContainer";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import HeaderLogo from "./HeaderLogo";
import "./header.css";
import { Link, List, ListItem } from "@mui/material";

const { bool, object } = PropTypes;

const menuModel = [
  {
    label: "الرئيسية",
    href: "home",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },
  {
    label: "ملف المنشأة",
    href: "establishmentProfile",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: true,
    isLogin: false,
  },
  {
    label: "التقييم الذاتي",
    href: "establishmentBasicDurationsPage",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: true,
    isLogin: false,
  },
  {
    label: "التدريب",
    href: "establishmentTrainings/trainings",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: true,
    isLogin: false,
  },
  {
    label: "عن المنصة",
    href: "about",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },
  {
    label: "المسار المميز",
    href: "specialTrack",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },

  {
    label: "الدليل التفاعلي",
    href: "interactiveGuide",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },

  {
    label: "الأسئلة الشائعة",
    href: "faq",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: null,
    isLogin: false,
  },
];

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isDropDownOpen: false,
      size: 0,
    };
  }

  toggle = () => {
    this.setState((state, props) => ({
      isOpen: !state.isOpen,
    }));
  };
  logIn() {
    window.location =
      process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
    window.location.reload();
  }
  logOut() {
    window.kc.logout();
  }
  render() {
    const { isLoggedIn, match, isExistingEstablishments } = this.props;
    const { isOpen } = this.state;

    const arrow_down = "img/arrow_down.png";
    const arrow_up = "img/arrow_up.png";
    const ImgSrc = this.state.isDropDownOpen ? arrow_down : arrow_up;

    const menuItems = menuModel
      .filter(
        ({ requiredLoginState }) =>
          requiredLoginState === null ||
          requiredLoginState === isExistingEstablishments
      )
      .map(({ label, href, isDropDown, DropDownItems }, i) => {
        if (isDropDown == true) {
          return (
            <div key={label} className={"bg-white"}>
              <ListItem
                className="px-0 flex grow border-4 border-blue-400 crusor whitespace-nowrap leading-relaxed"
                style={{ fontWeight: "bold" }}
              >
                <Link
                  onClick={() => {
                    this.setState({
                      isDropDownOpen: !this.state.isDropDownOpen,
                    });
                  }}
                >
                  {label}
                </Link>
                <img
                  key={i}
                  onClick={() => {
                    this.setState({
                      isDropDownOpen: !this.state.isDropDownOpen,
                    });
                  }}
                  src={ImgSrc}
                  className="dropDownArrow"
                />
              </ListItem>
              {this.state.isDropDownOpen && (
                <DropDownList items={DropDownItems} key={i} />
              )}
            </div>
          );
        } else {
          return (
            <ListItem
              className={`flex grow mobile-nav-item ${
                match.url.search(`${href}$`) > -1 ? "active-item" : ""
              }`}
              key={label}
            >
              <Link
                act
                className={`navigation-link ${
                  match.url.search(`${href}$`) > -1
                    ? "navigation-link-active"
                    : ""
                }`}
                active={match.url.search(`${href}$`) > -1}
                href={href && `/#/${href}`}
              >
                <Text Tag="span" fontSize={"font-18"} textType="roman">
                  {label}
                </Text>
              </Link>
            </ListItem>
          );
        }
      });
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        <div
          className="main-nav-blk "
          style={{
            flex: 3,
            display: "flex",
          }}
        >
          <div className="main-nav-toggle-wrapper">
            <MenuRoundedIcon
              sx={{ color: "#E49908", fontSize: 50 }}
              onClick={this.toggle}
            />
          </div>
          <List
            className={`navbar navbar-inverse main-nav mobile-nav-bar ${
              isOpen ? " opened" : ""
            } bg-white`}
            navbar
            style={{
              flex: 1,
              paddingBottom: 0,
              paddingTop: 0,
            }}
          >
            {isOpen && (
              <div
                onClick={() => {
                  this.setState({
                    isOpen: false,
                  });
                }}
                className="landing-page-sidebar-backdrop"
              />
            )}
            <div className="header-logo-wrapper mobile-only">
              <HeaderLogo isLogin={isLoggedIn} center={true} />
            </div>
            <div className={"w-full flex  md:items-center gap-x-4 menu-items"}>
              {menuItems}
            </div>

            <div className="header-horizontal-line mobile-only" />
            <div
              className={
                "flex items-center w-full justify-center mobile-only mobile-button-margins"
              }
            >
              {!isLoggedIn && isOpen ? (
                <Button
                  onClick={this.logIn}
                  text="تسجيل الدخول"
                  isBold={false}
                  bigButton
                  fontSize={"font-16"}
                  customTextSize="bold"
                />
              ) : (
                isOpen && (
                  <Button
                    onClick={this.logOut}
                    text="تسجيل الخروج"
                    isBold={false}
                    secondary
                    fontSize={"font-16"}
                    customTextSize="bold"
                  />
                )
              )}
            </div>
            {isOpen && (
              <div
                className={
                  "flex items-center w-full justify-center gap-x-4 border-4"
                }
                style={{
                  border: "none",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              >
                <AccessiblityContainer />
              </div>
            )}
          </List>
        </div>
        {!isLoggedIn && (
          <div className={"flex items-center gap-x-4"}>
            <div className={"hidden xl:flex items-center gap-x-4"}>
              <AccessiblityContainer />
            </div>
            <div className="login-btn-wrapper">
              <Button onClick={this.logIn} text="تسجيل الدخول" isBold={false} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

HeaderNav.propTypes = {
  isLoggedIn: bool,
  match: object.isRequired,
  isExistingEstablishments: bool,
};

export default withRouter(HeaderNav);
